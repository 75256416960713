import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {ProductionModel} from '../../production/_models/production.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {LotModel} from '@pages/lot/_models/lot.model';

@Injectable({
    providedIn: 'root'
})
export class LotService {
    API_URL = `${environment.api_host}/api/lot/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    findLots(queryParams?: QueryParamsModel): Observable<QueryResultsModel<LotModel>> {
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        if ( queryParams?.search.length > 0) {
            params = params.append('search_term', queryParams ? queryParams?.search : '');
        }

        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<LotModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<LotModel>) => {
                const queryResults = new QueryResultsModel<LotModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            })
        );
    }

    createLot(lot: LotModel) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ProductionModel>(url, lot.saveToAPI(), {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err);
                return of({ id: undefined });
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateLot(lot: LotModel): Observable<LotModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<LotModel>(url, lot.saveToAPI(), {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', lot, err);
                return of(lot);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number ): Observable<LotModel>{
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<LotModel>(url).pipe(
            catchError(err => {
                // this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return of(new LotModel());
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    findLotProductions(queryParams?: QueryParamsModel): Observable<QueryResultsModel<ProductionModel>> {
        // Para añadir el search o clauquier filtro solo falta añadir el queryParams en la petición
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `get-productions/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<ProductionModel>>(url, {params}).pipe(
            map((res) => {
                const queryResults = new QueryResultsModel<ProductionModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            })
        );
    }

    validationName(value: string, id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `validate-name/?name=${value}&id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(true);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    delete(id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `delete/${id}`;
        return this.http.delete<boolean>(url).pipe(
            catchError(() => {
                return of(false);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }
}
