import {Component, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ChecklistService} from '@core/_services/checklist/checklist.service';
import {ChecklistModel} from '@core/models/checklist/checklist.model';
import {TranslateService} from '@ngx-translate/core';
import {Media} from '@common/_models/media.model';
import {ResultControlChecklist, ResultsChecklist} from '@core/models/checklist/resultsChecklist.model';
import {of, Subscription, throwError} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {catchError, first, tap} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {FilePondComponent} from 'ngx-filepond/filepond.component';
import { AuthService } from "@modules/auth";
import {Router} from "@angular/router";
import {WalkthroughComponent} from "angular-walkthrough";
import {ReceptionService} from "@pages/reception/_services";

@Component({
    selector: 'app-checklist',
    templateUrl: './checklist.component.html',
    styleUrls: ['./checklist.component.scss'],
})


export class ChecklistComponent implements OnInit, OnChanges, OnDestroy {
    @Input() idChecklist: number;
    @Input() idResultChecklist: number;
    @Input() entity: string;
    @Input() entity_id: number;
    @Input() action: string;
    @Input() uploadType = 'img';
    @Output() state: EventEmitter<any> = new EventEmitter<any>();


    @ViewChild('myPond') myPond: FilePondComponent;
    uploadForm: UntypedFormGroup;
    description: string = null;
    public options: string[];
    public results: string[];
    public questionAnswers: any[];
    checklist: ChecklistModel = null;
    media: Media = new Media();
    showBigMedia = false;
    resultChecklist: ResultsChecklist = null;
    hasChange = false;
    formChecklist: UntypedFormGroup;
    isLoading$;
    private subscriptions: Subscription[] = [];

    formChecklistState: string;
    actionExecuted: string;
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;
    isChecklistCompleted: boolean = false;

    pondOptionsImg = {
        credits: false,
        allowMultiple: false,
        labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_IMAGES'),
        labelFileProcessing: this.translate.instant('COMMON.FILEPOND.UPLOADING'),
        labelFileProcessingComplete: this.translate.instant('COMMON.FILEPOND.UPLOAD_OK'),
        server: {
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                this.uploadForm.get('profile').setValue(file);
                const formData = new FormData();
                formData.append('image', this.uploadForm.get('profile').value);
                const sbCreate = this.checklistService.uploadImage(formData).pipe(
                    catchError((errorMessage) => {
                        this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                        error();
                        return throwError(errorMessage);
                    }),
                ).subscribe(async (res) => {
                    this.media = Media.jsonToModel(res);
                    this.resultChecklist.media = this.media.id;
                    this.myPond.options.labelFileProcessing = this.translate.instant('COMMON.FILEPOND.UPLOAD_OK');
                    this.hasChange = true;
                    this.notificationService.showActionNotification('COMMON.FILEPOND.UPLOAD_OK', MessageType.Success);
                    load(res);
                });
                this.subscriptions.push(sbCreate);
            }
        }
    };

    pondOptionsFile = {
        credits: false,
        allowMultiple: false,
        labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_FILES'),
        labelFileProcessing: this.translate.instant('COMMON.FILEPOND.UPLOADING'),
        labelFileProcessingComplete: this.translate.instant('COMMON.FILEPOND.UPLOAD_OK'),
        server: {
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                this.uploadForm.get('profile').setValue(file);
                const formData = new FormData();
                formData.append('file', this.uploadForm.get('profile').value);
                const sbCreate = this.checklistService.uploadFile(formData).pipe(
                    catchError((errorMessage) => {
                        this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                        error();
                        return throwError(errorMessage);
                    }),
                ).subscribe(async (res) => {
                    this.media = Media.jsonToModel(res);
                    this.resultChecklist.media = this.media.id;
                    this.myPond.options.labelFileProcessing = this.translate.instant('COMMON.FILEPOND.UPLOAD_OK');
                    this.hasChange = true;
                    this.notificationService.showActionNotification('COMMON.FILEPOND.UPLOAD_OK', MessageType.Success);
                    load(res);
                });
                this.subscriptions.push(sbCreate);
            }
        }
    };
    pondFiles = [];

    constructor(
        public modalController: ModalController,
        private checklistService: ChecklistService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private receptionService: ReceptionService,
    ) {
    }

    /** LOAD DATA */
    ngOnInit() {
        this.isLoading$ = this.checklistService.isLoading$;
        if (!this.entity) {
            this.entity = 'RECEPTION';
        }
        if (!this.entity_id) {
            this.entity_id = 9;
        }
        if (!this.idChecklist) {
            this.idChecklist = 1;
        }
        if (!this.entity_id || !this.idChecklist) {
            this.notificationService.showActionNotification('CHECKLIST.ERROR_COMPONENT.IDS_REQUIRED', MessageType.Error);
        } else {
            this.setOptions();
            this.loadData();
            this.uploadForm = this.fb.group({
                profile: ['']
            });
        }
    }

    ngOnChanges() {
        if (this.action === 'save') {
            this.save();
        }
        if (this.action === 'close') {
            this.closeModal();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    async closeModal() {
        await this.modalController.dismiss(this.hasChange);
    }

    async loadData() {
        await this.loadChecklist(this.idChecklist);
        if (this.idResultChecklist) {
            await this.loadResultsChecklist(this.idResultChecklist);
            this.description = this.resultChecklist.description;
            if (this.resultChecklist.media) {
                this.media = Media.jsonToModel(this.resultChecklist.media);
                this.pondFiles = [{source: this.media.reference,options:{type: 'limbo',file: {name:this.media.mediaInfo.name,size:this.media.mediaInfo.size,type:this.media.mediaInfo.type}}}];
            }
            this.getResult();
        } else {
            this.resultChecklist = new ResultsChecklist();
            this.resultChecklist.resultsControlsChecklist = [];
            this.getResult();
        }
        this.resultChecklist.entity = this.entity;
        this.resultChecklist.entity_id = this.entity_id;
        this.resultChecklist.checklist_id = this.idChecklist;
        this.loadForm();
        if(this.idResultChecklist) {
         this.disableForm();
        }
    }

    disableForm() {
        this.formChecklist.disable();
    }

    loadChecklist(id) {
        return new Promise((resolve) => {
            this.checklistService.getChecklistById(id).pipe(
                catchError((errorMessage) => {
                    console.log('error', errorMessage);
                    return of(new ChecklistModel());
                })
            ).subscribe((checklist: ChecklistModel) => {
                this.checklist = ChecklistModel.jsonToModel(checklist);
                resolve(true);
            });
        });
    }

    loadResultsChecklist(idResult) {
        return new Promise((resolve) => {
            this.checklistService.getResultsChecklist(idResult).pipe(
                catchError((errorMessage) => {
                    console.log('error', errorMessage);
                    return of(new ResultsChecklist());
                })
            ).subscribe((resultChecklist: ResultsChecklist) => {
                this.resultChecklist = ResultsChecklist.jsonToModel(resultChecklist);
                resolve(true);
            });
        });
    }


    getResult() {
        this.questionAnswers = [];
        this.checklist.checklistItems.forEach((question) => {
            const index = this.resultChecklist.resultsControlsChecklist.findIndex((elem) => elem.checklistItem_id == question.id);
            if (index > -1) {
                this.questionAnswers.push({
                    result: this.resultChecklist.resultsControlsChecklist[index].result,
                    value: this.resultChecklist.resultsControlsChecklist[index].value
                });
            } else {
                this.questionAnswers.push({result: null, value: ''});
            }
        });
        return true;
    }

    setOptions() {
        switch (this.entity) {
            case 'RECEPTION':
                this.options = ['CORRECT', 'INCORRECT', 'NOT_ABLE'];
                this.results = ['ACCEPTED', 'DETAINED', 'RETURNED'];
                break;
            case 'PRODUCTION':
                this.options = ['CORRECT', 'INCORRECT', 'NOT_ABLE'];
                break;
            case 'DELIVERY':
                this.options = ['CORRECT', 'INCORRECT', 'NOT_ABLE'];
                break;
            default:
                this.options = ['OK', 'NOT_OK'];
                this.results = ['OK', 'NOT_OK'];
                break;
        }
    }

    newOption(event, index, checklistItem_ID) {
        if (event.value) {
            this.questionAnswers[index].result = event.value;
        } else {
            this.questionAnswers[index].value = event.target.value;
        }

        const indice = this.resultChecklist.resultsControlsChecklist.findIndex((elem) => elem.checklistItem_id === checklistItem_ID);
        if (indice > -1) {
            if (event.value) {
                this.resultChecklist.resultsControlsChecklist[indice].result = event.value;
                if (!this.resultChecklist.resultsControlsChecklist[indice].value) {
                    this.resultChecklist.resultsControlsChecklist[indice].value = '';
                }
            } else {
                this.resultChecklist.resultsControlsChecklist[indice].value = event.target.value;
            }
        } else {
            const resultControl = new ResultControlChecklist();
            resultControl.id = 0;
            resultControl.checklistItem_id = checklistItem_ID;
            if (event.value) {
                resultControl.result = event.value;
                resultControl.value = '';
            } else {
                resultControl.value = event.target.value;
            }
            this.resultChecklist.resultsControlsChecklist.push(resultControl);
        }
        this.state.emit(this.formChecklist.status);
    }


    setDescription(event) {
        this.description = event.target.value;
    }

    // ******FORM */
    loadForm() {
        this.formChecklist = this.fb.group({
            description: [this.description, Validators.compose([Validators.maxLength(500)])],
            media: [this.resultChecklist.media],

        });
        let x = 0;
        this.checklist.checklistItems.forEach((question) => {
            this.formChecklist.addControl(question.name, this.fb.control(this.questionAnswers[x].result, [Validators.required]));
            if (question.name === 'TEMPERATURE') {
                this.formChecklist.addControl(question.name + '_value', this.fb.control(this.questionAnswers[x].value, [Validators.maxLength(5)]));
            }
            x++;
        });
    }


    save() {
        this.resultChecklist.description = this.description;
        if (this.resultChecklist.id) {
            this.closeModal();
        } else {
            this.create();
        }
        this.cdr.detectChanges();
    }

    create() {
        const sbCreate = this.checklistService.setResponseChecklist(this.resultChecklist).pipe(
            tap(async (res) => {
                this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
                await this.modalController.dismiss(true);
                if (this.actionExecuted == null || this.actionExecuted == 'updated') {
                    this.loadData();
                    this.closeModal();
                }
            }),
            catchError((errorMessage) => {
                console.log(errorMessage);
                this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                return of(this.resultChecklist);
            }),
        ).subscribe((res: ResultsChecklist) => {
            this.resultChecklist = res;
            this.cdr.detectChanges();
        });
        this.subscriptions.push(sbCreate);
        this.isChecklistCompleted = true;
    }

    pondHandleInit() {
    }

    pondHandleAddFile(event: any) {
    }

    pondHandleActivateFile(event: any) {
    }

}
