export const environment = {
  production: true,
  appVersion: '1.5.0',
  appEnvironment: '(qa)',
  USERDATA_KEY: 'auth',
  firebase: {
    apiKey: 'AIzaSyBQFpJjNm1qCXalVdG4E9wm6PlbFSw_pyc',
    authDomain: 'total-food-control-6ea17.firebaseapp.com',
    projectId: 'total-food-control-6ea17',
    storageBucket: 'total-food-control-6ea17.appspot.com',
    messagingSenderId: '603821098928',
    appId: '1:603821098928:web:15615e196f0344c64a3875',
    measurementId: 'G-6NPE9Y6JH9'
  },
  api_host: 'https://apiqa.totalfoodcontrol.com'
};
