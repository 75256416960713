import {BaseModel} from '@core/models/_base.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import {Media} from '@common/_models/media.model';
import { ObservationModel } from '@common/_models/observation';

export class MaterialModel extends BaseModel {
    id: number;
    name: string;
    enabled = true;
    max_stock: number;
    min_stock: number;
    days_expiration_alert: number;
    produced: boolean;
    current_stock = 0;
    percent_regularization = 0;
    measurement_unit: MeasurementUnitModel;
    providers: ProviderModel[];
    homologate: boolean;
    media: Media;
    note?: string;

    observations?: ObservationModel[];

    static jsonToModel(object) {
        const material = new MaterialModel();
        material.id = object.id;
        material.name = object.name;
        material.enabled = object.enabled;
        material.min_stock = +object.min_stock;
        material.max_stock = +object.max_stock;
        material.current_stock = +object.current_stock;
        material.percent_regularization = +object.percent_regularization;
        material.days_expiration_alert = +object.days_expiration_alert;
        material.produced = object.produced;
        material.measurement_unit = new MeasurementUnitModel();
        material.measurement_unit.id = object?.measurement_unit?.id;
        material.measurement_unit.name = object?.measurement_unit?.name;
        material.homologate = object?.homologate || false;
        material.providers = [];
        material.observations = [];

        object?.providers?.forEach((item: any) => {
            const provider = ProviderModel.jsonToModel(item);
            material.providers.push(provider);
        });

        object?.observations?.forEach((item: any) => {
            const observation = ObservationModel.jsonToModel(item);
            material.observations.push(observation);
        });

        material.media = Media.jsonToModel(object.media);

        return material;
    }

    public saveToAPI() {
        return {
            id: this.id,
            name: this.name,
            enabled: this.enabled,
            max_stock: this.max_stock,
            min_stock: this.min_stock,
            days_expiration_alert: this.days_expiration_alert,
            produced: this.produced,
            measurement_unit_id: this.measurement_unit.id,
            media: this.media?.id,
            observation: this.note,
        };
    }
}
