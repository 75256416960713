import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

enum MeasureUnits {
    KG = 'kg.',
    LITERS = 'l.',
    UDS = 'uds.',
    G = 'g.',
    ML = 'ml.',
}

type MeasureUnitKey = keyof typeof MeasureUnits;

@Pipe({
    standalone: true,
    name: 'measureShift'
})

export class MeasureShift implements PipeTransform {
    private liquidMeasureUnits = [MeasureUnits.ML, MeasureUnits.LITERS,];
    private solidMeasureUnits = [MeasureUnits.G, MeasureUnits.KG,];
    private threshold = 1000;

    constructor(private translateService: TranslateService) { }

    transform(value: number, unit: string): string {
        if (!value || !unit) return;

        const unitFromAPI: MeasureUnitKey = unit.split('.').pop() as MeasureUnitKey;
        const measureUnit = MeasureUnits[unitFromAPI];

        let finalValue = value;
        let finalUnit = measureUnit;

        //Liquids
        const indexLiquidMeasure = this.liquidMeasureUnits.indexOf(measureUnit);
        if (indexLiquidMeasure > -1 && indexLiquidMeasure < this.liquidMeasureUnits.length - 1 && value >= this.threshold) {
            finalValue = this.conversion(value);
            finalUnit = this.liquidMeasureUnits[indexLiquidMeasure + 1];
        }

        //Solids
        const indexSolidMeasure = this.solidMeasureUnits.indexOf(measureUnit);
        if (indexSolidMeasure > -1 && indexSolidMeasure < this.solidMeasureUnits.length - 1 && value >=this.threshold) {
            finalValue = this.conversion(value);
            finalUnit = this.solidMeasureUnits[indexSolidMeasure + 1];
        }

        //Rounded
        finalValue = Math.round(finalValue * 10) / 10;

        // Dots and commas

        const lang = this.translateService.currentLang;
        const thousandSeparators = {
            'es': '.',
            'en': ','
        };
        const decimalSeparators = {
            'es': ',',
            'en': '.'
        };

        const parts = finalValue.toString().split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1] ? parts[1] : '';

        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparators[lang]);

        const finalValueString = decimalPart ? `${formattedIntegerPart}${decimalSeparators[lang]}${decimalPart}` : formattedIntegerPart;

        return `${finalValueString} ${finalUnit}`;
    }

    private conversion(value): number {
        let convertedValue = value / 1000;
        convertedValue = convertedValue >= 1000 ?  Math.floor(convertedValue) : convertedValue;

        return convertedValue
    }
}
