import {BaseModel} from '@core/models/_base.model';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';
import {format, parse, toDate} from 'date-fns';
import {ObservationModel} from '@common/_models/observation';

export class ReceptionModel extends BaseModel {
    id: number;
    // tslint:disable-next-line:variable-name
    delivery_number: string;
    // tslint:disable-next-line:variable-name
    delivery_date: any;
    // tslint:disable-next-line:variable-name
    checklist_id: any;
    // tslint:disable-next-line:variable-name
    reception_items: ReceptionItem[];

    note?: string;

    observations?: ObservationModel[];

    pending: boolean;

    static jsonToModel(object) {
        const reception = new ReceptionModel();
        reception.id = object.id;
        reception.delivery_number = object.delivery_number;
        reception.delivery_date = new Date(object.delivery_date);
        reception.checklist_id = object.checklist_id;
        reception.pending = object.pending;
        reception.observations = [];
        reception.reception_items = [];

        object?.observations?.forEach((item: any) => {
          const observation = ObservationModel.jsonToModel(item);
          reception.observations.push(observation);
        });

        object?.reception_items?.forEach((item: any) => {
            const receptionItem = ReceptionItem.jsonToModel(item);
            reception.reception_items.push(receptionItem);
        });

        return reception;
    }

    saveToAPI(){
        const toJson = {
            id: this.id,
            delivery_number: this.delivery_number,
            delivery_date: format(this.delivery_date, 'yyyy-MM-dd'),
            observation: this.note,
            pending: this.pending,
            reception_items: []
        };

        toJson.reception_items = this.reception_items.map((item) => {
            return {
                id: item?.id,
                provider_id: item.provider.id,
                raw_material_id: item.raw_material.id,
                wharehouse_id: item.wharehouse.id,
                cant: item.cant,
                num_lote: item.num_lote,
                date_expiry: format(item.date_expiry, 'yyyy-MM-dd'),
            };
        });

        return toJson;
    }
}
