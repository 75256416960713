import {BaseModel} from '@core/models/_base.model';

export class ObservationModel extends BaseModel{
    id: number;
    message: string;
    createdAt: Date;

    static jsonToModel(object) {
            const observation = new ObservationModel();
            observation.id = object.id;
            observation.message = object.message;
            observation.createdAt = new Date(object.createdAt);
            return observation;
    }
}
