import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductionModel} from '@pages/production/_models/production.model';
import {Observable, Subscription, throwError} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {LotService, ProductionService} from '@pages/production/_services';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {catchError, debounceTime, finalize, first, switchMap, tap} from 'rxjs/operators';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {CookingRecipeModel} from '@pages/cooking-recipe/_models/cooking-recipe.model';
import {CookingRecipeService} from '@pages/cooking-recipe/_services';
import {MatStepper} from '@angular/material/stepper';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {LotModel} from '@pages/lot/_models/lot.model';
import {NewLotModalPage} from '@pages/lot/components/new-lot-modal/new-lot-modal.page';
import {MatExpansionPanel} from '@angular/material/expansion';
import {rowMaterialValidators} from '@pages/production/validators/rowMaterialValidator';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import {WarehouseService} from '@pages/warehouse/_services';
import {ProductionItem} from '@pages/production/_models/productionItem.model';
import {validateStockByQuantityValidator} from '@pages/production/validators/validateStockByQuantityValidator';
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {MissingStockModel} from '@pages/production/_models/missingStock.model';
import {NewReceptionMaterialModalPage} from '@pages/reception/components/new-reception-material-modal/new-reception-material-modal.page';


@Component({
    selector: 'app-new-production-cooking-recipe-modal',
    templateUrl: './new-production-cooking-recipe-modal.page.html',
    styleUrls: ['./new-production-cooking-recipe-modal.page.scss'],
    viewProviders: [MatExpansionPanel]
})
export class NewProductionCookingRecipeModalPage implements OnInit, OnDestroy, AfterViewInit {
    id: number;
    planned: boolean;
    plannedToProd: false;
    cookingRecipe: CookingRecipeModel;

    @ViewChild('stepper') stepper: MatStepper;

    isLoading$;
    isSearchCookingRecipe = true;
    isSearchLot = false;
    formGroup: UntypedFormGroup;
    udsByCant: number;
    totalUds: number;
    percentProduction: number;
    today = new Date();
    autoGenerateStep2 = false;

    filteredCookingRecipeOptions: CookingRecipeModel[];
    filteredLotOptions: LotModel[];

    lastCookingRecipes: Observable<QueryResultsModel<CookingRecipeModel>>;
    recentCookingRecipes: Observable<QueryResultsModel<CookingRecipeModel>>;
    cookingRecipeList: Observable<QueryResultsModel<CookingRecipeModel>>;
    lotList: Observable<QueryResultsModel<LotModel>>;
    requiredRawMaterials: MissingStockModel[];

    warehousesList: Observable<QueryResultsModel<WarehouseModel>>;
    warehouseSelect: WarehouseModel = new WarehouseModel();

    selectedLot: LotModel;

    // tslint:disable-next-line:variable-name
    production_items = new UntypedFormArray([]);
    production: ProductionModel;

    // checklist
    checklistAction = 'show';
    formChecklistState = 'INVALID';
    actionExecuted = 'started';

    currentStep = 0;

    note: string;

    private subscriptions: Subscription[] = [];

    constructor(
        private modalController: ModalController,
        private productionService: ProductionService,
        private lotService: LotService,
        private cookingRecipeService: CookingRecipeService,
        private warehouseService: WarehouseService,
        private fb: UntypedFormBuilder,
        private dateAdapter: DateAdapter<Date>,
        private notificationService: NotificationService,
    ) {
        this.dateAdapter.setLocale('es-ES');
    }

    ngOnInit() {
        this.isLoading$ = this.productionService.isLoading$;
        this.loadProduction();
        this.loadLastCookingRecipes();
        this.loadRecentCookingRecipes();
        this.loadCookingRecipes();
        this.loadLots();
        this.loadWarehouse();

        this.loadFirstCookingRecipes();
        this.loadFirstlots();
    }

    ngAfterViewInit() {
        this.stepper.selectionChange.subscribe((event) => {
            this.currentStep = event.selectedIndex;

            if (this.currentStep <2) {
                this.resetAutoSelectStock();
            }
        });

        // if temporal cooking recipe
        if (this.cookingRecipe) {
            this.formGroup.patchValue({
                cooking_recipe: this.cookingRecipe,
                total: this.cookingRecipe.cant_total
            });
            if (!this.plannedToProd) {
                this.onPrepereProductionItems(this.cookingRecipe);
            }
            this.formGroup.get('cooking_recipe').markAsTouched();
            this.formGroup.get('total').markAsTouched();
            this.totalUds = this.cookingRecipe.unit_total;
            this.getUnitsByKg();
            this.stepper.selectedIndex = 1;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    loadProduction() {
        if (!this.id) {
            this.production = new ProductionModel();
            this.loadForm();
        } else {
            const sb = this.productionService.getItemById(this.id).pipe(
                first(),
                catchError(async (errorMessage) => {
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    await this.modalController.dismiss(false);
                    return throwError(errorMessage);
                })
            ).subscribe((production: ProductionModel) => {
                this.production = ProductionModel.jsonToModel(production);
                this.totalUds =
                    ((this.production.total * this.production.cooking_recipe.unit_total) / this.production.cooking_recipe.cant_total);
                // Calculamos el % de producción para luego ver que cantidad de materiales se debe seleccionar
                this.percentProduction = (this.totalUds / this.production.cooking_recipe.unit_total) * 100;
                this.loadForm();
                this.setCookingRecipe(this.production.cooking_recipe, this.production.total);
                this.setLot(this.production.lot);
                this.warehouseSelect = this.production.warehouse;

                setTimeout(() => {
                    if (this.plannedToProd) {
                        this.production.production_items.forEach((item) => {
                            this.addProductionWithValuesItemForm(item.cant, item.production_item_has_reception_items);
                        });
                    }
                }, 2000);

                setTimeout(() => {
                    this.stepper.selectedIndex = 1;
                }, 200);
            });
            this.subscriptions.push(sb);
        }
    }

    addValidatorRecipe() {
        const cookingRecipe = this.formGroup.get('cooking_recipe').value;
        this.formGroup.get('total').setAsyncValidators([validateStockByQuantityValidator(this.productionService, cookingRecipe?.id)]);
    }

    loadForm() {
        const control = this.fb.group({
            cooking_recipe: [this.production.cooking_recipe, Validators.compose([Validators.required])],
            total: [this.production.total, Validators.compose([Validators.required])],
            date_expiry: [this.production?.date_expiry],
            lot: [this.production.lot, Validators.compose([Validators.required])],
            warehouse: [this.production.warehouse],
            production_items: this.fb.array([]),
        });

        this.formGroup = control;
        this.production_items = this.formGroup.get('production_items') as UntypedFormArray;

        control?.get('cooking_recipe').valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isSearchCookingRecipe = true),
            switchMap(value => this.cookingRecipeService.findCookingRecipes(new QueryParamsModel(value)).pipe(
                finalize(() => this.isSearchCookingRecipe = false),
            ))
        ).subscribe(data => this.filteredCookingRecipeOptions = data.results);

        control?.get('lot').valueChanges.pipe(
            debounceTime(300),
            tap((value) => {
                this.isSearchLot = true;
                if (value !== this.selectedLot?.name) {
                    this.selectedLot = undefined;
                }
            }),
            switchMap(value => this.lotService.findLots(new QueryParamsModel(value, null, null, 0, 100, {only_open: true})).pipe(
                finalize(() => this.isSearchLot = false),
            ))
        ).subscribe(data => this.filteredLotOptions = data.results);


    }

    addProductionItemForm(cant: number) {
        const control = this.fb.group({
            id: [],
            items_receptions: [[]],
            cant: [cant],
        }, {validators: rowMaterialValidators});
        this.production_items.push(control);
    }

    addProductionWithValuesItemForm(cant: number, receptionItems: any[]) {
        const control = this.fb.group({
            id: [],
            items_receptions: [receptionItems],
            cant: [cant],
        }, {validators: rowMaterialValidators});
        this.production_items.push(control);
    }

    loadCookingRecipes() {
        this.cookingRecipeList = this.cookingRecipeService.findCookingRecipes();
    }

    loadLots() {
        this.lotList = this.lotService.findLots(new QueryParamsModel('', null, null, 0, 100, {only_open: true}));
    }

    loadLastCookingRecipes() {
        this.lastCookingRecipes = this.productionService.getLastCookingRecipes();
    }

    loadRecentCookingRecipes() {
        this.recentCookingRecipes = this.productionService.getRecentCookingRecipes();
    }

    loadWarehouse() {
        this.warehousesList = this.warehouseService.findWarehouse();
        this.warehousesList.subscribe(data => {
            if (data?.results[0]) {
                this.warehouseSelect = data.results[0];
                this.formGroup.patchValue({
                    warehouse: this.warehouseSelect as WarehouseModel,
                });
            }
        });
    }

    loadFirstCookingRecipes() {
        this.isSearchCookingRecipe = true;
        const loadFirstCookingRecipes = this.cookingRecipeService.findCookingRecipes().pipe(
            finalize(() => this.isSearchCookingRecipe = false),
        ).subscribe(data => this.filteredCookingRecipeOptions = data.results);
        this.subscriptions.push(loadFirstCookingRecipes);
    }

    loadFirstlots() {
        this.isSearchLot = true;
        const loadFirstLots = this.lotService.findLots(new QueryParamsModel('', null, null, 0, 100, {only_open: true})).pipe(
            finalize(() => this.isSearchLot = false),
        ).subscribe(data => this.filteredLotOptions = data.results);
        this.subscriptions.push(loadFirstLots);
    }

    setCookingRecipe(cookingRecipe: CookingRecipeModel, total?: number) {
        this.formGroup.patchValue({
            cooking_recipe: cookingRecipe,
        });
        this.addValidatorRecipe();
        this.formGroup.patchValue({
            total: total || (cookingRecipe.cant_total * 1)
        });
        if (!this.plannedToProd) {
            this.onPrepereProductionItems(cookingRecipe);
        }
        this.formGroup.get('cooking_recipe').markAsTouched();
        this.formGroup.get('total').markAsTouched();
        this.totalUds = this.getValue('cooking_recipe')?.unit_total;
        this.getUnitsByKg();
    }

    onSelectAutoCompletedRecipe(stepper: MatStepper, event) {
        const value = event.option.value;
        this.setCookingRecipe(value);
        stepper.next();
    }

    setLot(value: LotModel) {
        this.selectedLot = value;
        this.formGroup.patchValue({
            lot: value?.name,
        });
        this.formGroup.get('lot').markAsTouched();
    }

    onSelectAutoCompletedLot(event) {
        const value = event.option.value;
        this.setLot(value);
    }

    onCookingRecipeSelect(stepper: MatStepper, value) {
        this.setCookingRecipe(value);
        stepper.next();
    }

    /*
    *  Método para rellenar los items del formulario al seleccionar una receta
    */
    onPrepereProductionItems(value: CookingRecipeModel) {
        // generate productionsrecipe item by each item in cookiengrecipe select
        this.production_items.clear();
        value?.cooking_recipe_items.forEach((item) => {
            const cant = (item.cant * (this.percentProduction / 100)).toFixedEpsilon();
            return this.addProductionItemForm(cant);
        });
    }

    onChangeQuantity(value: number) {
        const cookingRecipe = this.formGroup.get('cooking_recipe')?.value;
        this.totalUds = ((value * cookingRecipe.unit_total) / cookingRecipe.cant_total);
        // Calculamos el % de producción para luego ver que cantidad de materiales se debe seleccionar
        this.percentProduction = (this.totalUds / cookingRecipe.unit_total) * 100;
        this.requiredRawMaterials = [];
    }

    moreInfoEnoughStock() {
        this.productionService.missingRecipeCurrentStock(this.formGroup.get('total')?.value, this.formGroup.get('cooking_recipe')?.value.id).subscribe({
            next: value => {
                this.requiredRawMaterials = value;
            }
        });
    }

    onCompletedQuantity() {
        const cookingRecipe = this.formGroup.get('cooking_recipe')?.value;
        this.onPrepereProductionItems(cookingRecipe);
    }

    async closeModal(data = false) {
        await this.modalController.dismiss(data);
    }

    stepBack() {
        this.stepper.previous();
    }


    async closeModalWithOK(stepper) {
        if (this.actionExecuted === 'created') {
            this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
        }
        if (this.actionExecuted === 'updated') {
            this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
        }
        stepper.next();
        await this.wait(2500);
        await this.modalController.dismiss(true);
    }

    checkState(state) {
        this.formChecklistState = state;
    }

    emitToSaveChecklist(stepper) {
        stepper.next();
        if (this.production.checklist_id) {
            this.checklistAction = 'close';
            this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
        } else {
            this.checklistAction = 'save';
        }
    }


    private prepareProduction() {
        const formData = this.formGroup.value;
        this.production.lot = this.selectedLot;
        this.production.cooking_recipe = formData.cooking_recipe;
        this.production.warehouse = formData.warehouse;
        this.production.total = formData.total;
        this.production.planned = this.planned;
        this.production.production_items = [];
        if (this.note && this.note.trim() !== '') {
            this.production.note = this.note.trim();
        } else {
            this.production.note = undefined;
        }

        formData.production_items.map((item) => {
            this.production.production_items.push({
                id: item.id,
                cant: item.cant,
                production_item_has_reception_items: item.items_receptions,
            } as ProductionItem);
        });
    }

    save(stepper: MatStepper) {
        this.prepareProduction();
        if (this.production.id) {
            this.edit(stepper);
        } else {
            this.create(stepper);
        }
    }

    private create(stepper: MatStepper) {
        this.production.date_expiry =
            moment(this.production.lot.date_of_close).add(this.production.cooking_recipe.expiration_days, 'd').toDate();
        const sbCreate = this.productionService.createProduction(this.production).pipe(
            catchError((errorMessage) => {
                console.log(errorMessage);
                this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                return throwError(errorMessage);
            }),
        ).subscribe(async (res: ProductionModel) => {
            this.production = res;
            this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
            this.actionExecuted = 'created';
            if (this.planned) {
                await this.closeModalWithOK(stepper);
            } else {
                stepper.next();
            }
            this.id = res.id;
        });
        this.subscriptions.push(sbCreate);
    }

    private edit(stepper: MatStepper) {
        const sbUpdate = this.productionService.updateProduction(this.production).pipe(
            catchError((errorMessage) => {
                console.log(errorMessage);
                this.notificationService.showActionNotification('COMMON.DATA_UPDATED_ERROR', MessageType.Error);
                return throwError(errorMessage);
            }),
        ).subscribe(async (res: ProductionModel) => {
            this.production = res;
            this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
            this.actionExecuted = 'updated';
            if (this.planned) {
                await this.closeModalWithOK(stepper);
            } else {
                stepper.next();
            }

        });
        this.subscriptions.push(sbUpdate);
    }

    stepperNext(stepper: MatStepper) {
        if (stepper.selectedIndex === 4) {
            this.save(stepper);
        } else {
            stepper.next();
        }
    }

    async openNewLotProduction() {
        const name = this.getValue('lot');
        const modal = await this.modalController.create({
            component: NewLotModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined,
                name
            }
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.selectedLot = response.data?.lot;
                this.formGroup.patchValue({
                    lot: this.selectedLot.name
                });
            }
        });

        return await modal.present();
    }

    async openNewReception() {
        const modal = await this.modalController.create({
            component: NewReceptionMaterialModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        modal.onDidDismiss().then((response) => {
            this.formGroup.get('total').updateValueAndValidity();
            this.moreInfoEnoughStock();
        });

        return await modal.present();
    }

    getUnitsByKg() {
        const cookingRecipe = this.formGroup.get('cooking_recipe')?.value;
        this.udsByCant = Math.round((cookingRecipe?.unit_total / cookingRecipe?.cant_total) * 10) / 10;
    }

    toggleClass(item) {
        item.active = !item.active;
        this.warehouseSelect = item;
        this.formGroup.patchValue({
            warehouse: item as WarehouseModel,
        });
    }

    wait(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation, controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    }

    getValue(field: string): any {
        const control = this.formGroup.get(field);
        return control.value;
    }

    onAutoSelectStock() {
        this.autoGenerateStep2 = true;
    }

    resetAutoSelectStock() {
        this.autoGenerateStep2 = false;
    }

    isObject(val): boolean {
        return typeof val === 'object';
    }
}
