import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MaterialModel} from '../../_models/material.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';
import {MasterMeasurementUnitService} from '@common/_services';
import {AbstractControl, FormControl, FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MaterialService} from '@pages/material/_services';
import {catchError, first} from 'rxjs/operators';
import {of, Subscription, throwError} from 'rxjs';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {StockValidators} from '@pages/material/validators/stockValidator';
import {nameAsyncValidator} from '@core/validators/AsyncName.validators';
import {TranslateService} from '@ngx-translate/core';
import {Media} from '@common/_models/media.model';
import {ErrorStateMatcher} from '@angular/material/core';
import {WalkthroughComponent} from 'angular-walkthrough';
import {AuthService} from '@modules/auth';
import { notEmptyValidator } from '@core/validators/NotEmpty.validators';

class FromStockStateMarcher implements ErrorStateMatcher {
  private errorCode: string;


  constructor(errorCode: string) {
    this.errorCode = errorCode;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched || control.dirty) && (control.invalid || form.hasError(this.errorCode));
  }
}

@Component({
  selector: 'app-new-material-modal',
  templateUrl: './new-material-modal.page.html',
  styleUrls: ['./new-material-modal.page.scss'],
})
export class NewMaterialModalPage implements OnInit, OnDestroy {
  id: number;
  name: string; // Si se envía el parámetro al mostrar el modal se toma como nombre para inicializar el formulario
  isLoading$;
  material: MaterialModel;
  measurementUnits: MeasurementUnitModel[] = [];
  formGroup: UntypedFormGroup;
  hasChange = false;
  note: string;

  readonly stockStateMatcher = new FromStockStateMarcher('stock');

  private subscriptions: Subscription[] = [];
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;
  media: Media = new Media();
  @ViewChild('myPond') myPond: any;
  uploadForm: UntypedFormGroup;

  pondOptions = {
    credits: false,
    allowMultiple: false,
    labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_FILES'),
    labelFileProcessing: this.translate.instant('COMMON.FILEPOND.UPLOADING'),
    labelFileProcessingComplete: this.translate.instant('COMMON.FILEPOND.UPLOAD_OK'),
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        this.uploadForm.get('profile').setValue(file);
        const formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);
        const sbCreate = this.materialService.uploadMaterialMedia(formData).pipe(
            catchError((errorMessage) => {
              this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
              error();
              return throwError(errorMessage);
            }),
        ).subscribe(async (res) => {
          this.media = Media.jsonToModel(res);
          this.material.media = this.media;
          this.myPond.options.labelFileProcessing = this.translate.instant('COMMON.FILEPOND.UPLOAD_OK');
          this.hasChange = true;
          this.notificationService.showActionNotification('COMMON.FILEPOND.UPLOAD_OK', MessageType.Success);
          load(res);
        });
        this.subscriptions.push(sbCreate);

      }
    }
  };

  pondFiles = [];

  constructor(
      private modalController: ModalController,
      private measurementUnitService: MasterMeasurementUnitService,
      private fb: UntypedFormBuilder,
      private materialService: MaterialService,
      private notificationService: NotificationService,
      private translate: TranslateService,
      private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this.materialService.isLoading$;
    this.measurementUnitService.findMeasurementUnit().subscribe(
        (data) => {
          this.measurementUnits = data;
        },
        (error) => {
          console.log('ERROR:' + error);
        }
    );
    this.uploadForm = this.fb.group({
      profile: ['']
    });
    this.loadMaterial();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  loadMaterial() {
    if (!this.id) {
      this.material = new MaterialModel();
      this.loadForm();
    } else {
      const sb = this.materialService.getItemById(this.id).pipe(
          first(),
          catchError((errorMessage) => {
            console.log('error', errorMessage);
            return of(new MaterialModel());
          })
      ).subscribe((material: MaterialModel) => {
        this.material = MaterialModel.jsonToModel(material);
        this.loadForm();
        if (this.material.media) {
          this.pondFiles = [{
            source: this.material.media.reference,
            options: {
              type: 'limbo',
              file: {
                name: this.material.media.mediaInfo.name,
                size: this.material.media.mediaInfo.size,
                type: this.material.media.mediaInfo.content_type
              }
            }
          }];
        }

      });
      this.subscriptions.push(sb);
    }
  }

  async closeModal() {
    await this.modalController.dismiss(this.hasChange);
  }

    loadForm() {
        this.formGroup = this.fb.group({
            name: [this.id ? this.material.name : this.name, Validators.compose([Validators.required, notEmptyValidator, Validators.minLength(3), Validators.maxLength(100)]), [nameAsyncValidator(this.materialService, this.material.id)]],
            min_stock: [this.material.min_stock, Validators.compose([Validators.required, Validators.pattern('^\\d[\\d,]*(\\.\\d+)?$')])],
            max_stock: [this.material.max_stock, Validators.compose([Validators.required, Validators.pattern('^\\d[\\d,]*(\\.\\d+)?$')])],
            days_expiration_alert: [this.material.days_expiration_alert, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
            measurement_unit: [this.material.measurement_unit, Validators.compose([Validators.required])],
            enabled: [this.material.enabled]
        }, {validators: StockValidators});
    }

  save() {
    this.prepareMaterial();
    if (this.material.id) {
      this.edit();
    } else {
      this.create();
    }
  }

  edit() {
    const sbUpdate = this.materialService.updateMaterial(this.material).pipe(
        catchError((errorMessage) => {
          console.log(errorMessage);
          this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
          return throwError(errorMessage);
        }),
    ).subscribe(async (res: MaterialModel) => {
      this.material = res;
      this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
      await this.modalController.dismiss(true);
    });
    this.subscriptions.push(sbUpdate);
  }

  create() {
    const sbCreate = this.materialService.createMaterial(this.material).pipe(
        catchError((errorMessage) => {
          console.log(errorMessage);
          this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
          return throwError(errorMessage);
        }),
    ).subscribe(async (res: MaterialModel) => {
      this.material = res;
      this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);

      this.continueOnboarding();
      await this.modalController.dismiss(true);
    });
    this.subscriptions.push(sbCreate);
  }

  private prepareMaterial() {
    const formData = this.formGroup.value;
    this.material.name = formData.name;
    this.material.min_stock = formData.min_stock;
    this.material.max_stock = formData.max_stock;
    this.material.days_expiration_alert = formData.days_expiration_alert;
    this.material.measurement_unit = formData.measurement_unit;
    this.material.enabled = formData.enabled;
    if (this.note && this.note.trim() !== '') {
      this.material.note = this.note.trim();
    } else {
      this.material.note = undefined;
    }
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }

  objectComparisonFunction(option, value): boolean {
    return option?.id === value?.id;
  }

  maxStockValid() {
    const minStock = this.formGroup.get('min_stock').value;
    const maxStock = this.formGroup.get('max_stock').value;
    return maxStock > minStock;
  }

  private continueOnboarding() {
    WalkthroughComponent.walkthroughContinue();
    WalkthroughComponent.walkthroughNext();
  }
}
