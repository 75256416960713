import { ObservationModel } from '@common/_models/observation';
import {BaseModel} from '@core/models/_base.model';
import {format} from 'date-fns';

export class LotModel extends BaseModel {
    id: number;
    // tslint:disable-next-line:variable-name
    name: string;
    // tslint:disable-next-line:variable-name
    num: number;
    // tslint:disable-next-line:variable-name
    date_of_close:Date = new Date();
    // tslint:disable-next-line:variable-name
    date_of_create:Date = new Date();

    note?: string;

    observations?: ObservationModel[];

    static jsonToModel(object) {
        const lot = new LotModel();
        lot.id = object.id;
        lot.name = object.name;
        lot.num = +object.num;
        lot.date_of_close =  new Date(object.date_of_close);
        lot.date_of_create =  new Date(object.date_of_create);
        lot.observations = [];

        object?.observations?.forEach((item: any) => {
            const observation = ObservationModel.jsonToModel(item);
            lot.observations.push(observation);
        });

        return lot;
    }

    saveToAPI(){
        return {
            id: this.id,
            name: this.name,
            date_of_close: format(this.date_of_close, 'yyyy-MM-dd'),
            date_of_create: format(this.date_of_create, 'yyyy-MM-dd'),
            observation: this.note,
        };
    }
}
